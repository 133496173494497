import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FormInput, Button } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { authStaff } from '../../utils/constants';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';

function Flag({ isOpen, isClosed, client }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [reason, setReason] = useState('');
    const [recommendation, setRecommendation] = useState('');
    const [processing, setProcessing] = useState(false);
    const accessToken = useSelector(selectAccessToken);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcessing(true);
        
        let data = {
            firstName : client?.otherNames,
            lastName : client?.surname,
            phoneNumber : client?.phone,
            reason,
            recommendation
        }

        let url = `/loans/staff/blacklist`;
        const headers = authStaff(accessToken);

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcessing(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `${res?.message}`
        }))

        // close modal
        isClosed();

        setTimeout(() => {
            // reload the page after 2 seconds
            window.location.reload();
        }, 2000)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={isClosed}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                        <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                        <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900 mb-2">
                            Flag this account
                        </Dialog.Title>
                        
                        <form className='grid grid-cols-1 md:grid-cols-3 gap-y-6 gap-x-4 rounded-md py-4'>
                            <FormInput 
                                label={"Client's First Name"}
                                type="text"
                                name="firstName"
                                id="firstName"
                                disabled={true}
                                value={client?.otherNames}
                            /> 

                            <FormInput
                                label={"Client's Last Name"}
                                type="text"
                                name="lastName"
                                id="lastName"
                                disabled={true}
                                value={client?.surname}
                            />

                            <FormInput
                                label={"Client's Phone Number"}
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                disabled={true}
                                value={client?.phone}
                            />

                            <div className='col-span-full'>
                                <label htmlFor="reason" className='form-label'>Reason for flagging:</label>
                                <textarea 
                                    name="reason" 
                                    id="reason" 
                                    className='form-textarea' 
                                    rows={4}
                                    required 
                                    value={reason} 
                                    onChange={(e) => setReason(e.target.value)} 
                                    placeholder='Indicate reason for flagging this account'
                                />
                            </div>

                            <div className='col-span-full'>
                                <label htmlFor="recommendation" className='form-label'>Recommendation:</label>
                                <textarea 
                                    name="recommendation" 
                                    id="recommendation" 
                                    className='form-textarea' 
                                    rows={4}
                                    required 
                                    value={recommendation} 
                                    onChange={(e) => setRecommendation(e.target.value)} 
                                    placeholder='Indicate your recommendation'
                                />
                            </div>
                        </form>

                        <div className="inline-flex items-center space-x-4 mt-4">
                            <Button 
                                btnText={"Submit"}
                                btnClasses={"danger-button w-auto px-6"}
                                btnType="button"
                                btnFunction={handleRequest}
                                processing={processing}
                            />
                            <Button 
                                btnText={"Cancel"}
                                btnClasses={"form-button-default w-auto px-6"}
                                btnType="button"
                                btnFunction={isClosed}
                            />
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default Flag