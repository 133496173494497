import React, { useState } from 'react'
import { Button } from "../../components";
import { authStaff, dateToHumanFormat } from '../../utils/constants';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectStaff } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Item({ item, setItems }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const staff = useSelector(selectStaff);
    // const disabledAgent = reversalAdmins.includes(staff?.email);
    const disabledAgent = item?.approvals.filter((app) => app.email === staff?.email);
    const [toggled, setToggled] = useState(false);
    const [process, setProcess] = useState(false);
    const [denyProcess, setDenyProcess] = useState(false);

    const handleToggle = () => {
        let content = document.getElementById(`content-${item?._id}`);
        if(content.classList.contains("hidden")){
            content.classList.remove("hidden");
            setToggled(true);
        } else {
            content.classList.add("hidden");
            setToggled(false);
        }
    }

    const handleApprove = async() => {
        setProcess(true);

        let data = {
            _id : item?._id,
            status : true
        }

        let url = `/loans/staff/reversals`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "PATCH", headers, {}, data);
        setProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : res?.message
        }))

        // update list
        setTimeout(() => {
            setItems(res?.list)
        }, 2700)
        
    }

    const handleDeny = async() => {
        setDenyProcess(true);

        let data = {
            _id : item?._id,
            status : false
        }

        let url = `/loans/staff/reversals`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "PATCH", headers, {}, data);
        setDenyProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : res?.message
        }))

        // update list
        setTimeout(() => {
            setItems(res?.list)
        }, 2700)
        
    }

    return (
        <div className='p-4 flex flex-col items-center space-y-4'>
            <div className='w-full flex item-center justify-between'>
                <div className='inline-flex items-center space-x-4 font-medium divide-x-2'>
                    <span className=''>{item?.client}</span>
                    <span className='hidden md:block pl-4'>{item?.loanType}</span>
                    <span className='hidden md:block pl-4'>{item?.phone}</span>
                    <span className='hidden md:block pl-4'>{dateToHumanFormat(item?.createdAtms)}</span>
                </div>

                <div className='flex items-center space-x-4'>
                    <span className={`text-slate-400 text-sm transition-all duration-200 delay-175 ${toggled ? 'hidden' : ''}`}>
                        {item?.approvals.length} out of 2 have approved
                    </span>

                    <button type='button' className='p-3 text-sm bg-gray-100 rounded-full' onClick={handleToggle}>
                        <ChevronDownIcon 
                            className={`w-5 h-5 transition-all duration-200 delay-175 ${toggled ? 'rotate-180' : 'rotate-0'}`}
                        />
                    </button>
                </div>
            </div>

            <div id={`content-${item?._id}`} className='w-full transition-all duration-200 delay-175 p-2 hidden'>

                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                                <tr>
                                    <th scope="col" className='px-4 py-3'>Details</th>
                                    <th scope="col" className='px-4 py-3'>Old</th>
                                    <th scope="col" className='px-4 py-3'>New</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                    <td className='px-4 py-3'>
                                        Transaction ID
                                    </td>
                                    <td className='px-4 py-3'>
                                        {item?.oldTransID}
                                    </td>
                                    <td className='px-4 py-3'>
                                        {item?.newTransID}
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                    <td className='px-4 py-3'>
                                        Transaction Date
                                    </td>
                                    <td className='px-4 py-3'>
                                        {dateToHumanFormat(item?.oldDate)}
                                    </td>
                                    <td className='px-4 py-3'>
                                        {dateToHumanFormat(item?.newDate)}
                                    </td>
                                </tr>
                                <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                    <td className='px-4 py-3'>
                                        Amount
                                    </td>
                                    <td className='px-4 py-3'>
                                        {item?.oldAmount}
                                    </td>
                                    <td className='px-4 py-3'>
                                        {item?.newAmount}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='p-2 text-slate-500 my-6'>
                    {item?.comment}
                </div>

                <div className='p-2 flex items-center justify-between text-sm'>
                    <span className='text-slate-400'>
                        {item?.approvals.length} out of 2 have approved
                    </span>

                    <div className={`inline-flex items-center space-x-4 ${disabledAgent.length > 0 ? 'hidden' : ''}`}>
                        <Button 
                            btnText={"Approve"}
                            btnType={"button"}
                            btnClasses={"px-3 py-1.5 bg-light-blue rounded-md text-white"}
                            btnFunction={handleApprove}
                            processing={process}
                        />

                        <Button 
                            btnText={"Deny"}
                            btnType={"button"}
                            btnClasses={"px-3 py-1.5 bg-red-500 rounded-md text-white"}
                            btnFunction={handleDeny}
                            processing={denyProcess}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item