import { useState } from 'react';
import { Button } from "../../../components";
import { authStaff } from "../../../utils/constants";
import { uploadFileRequest } from "../../../Requests/getRequests";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../../slices/staffSlice";
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function FileUpload({ loan }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [fileType, setFileType] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [btnState, setBtnState] = useState(true);
    const [process, setProcess] = useState(false);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
        setBtnState(false);
    }

    const uploadRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(fileType === ""){
            setProcess(false);
            dispatch(show({
                type : "error",
                state : true,
                message : "Please select a file type"
            }))
            return;
        }

        let url =  `/loans/staff/upload-docs`;
        let headers = {
            ...authStaff(accessToken),
            "Content-Type" : "multipart/form-data"
        }

        let formData = new FormData();
        formData.append(fileType, selectedFile);
        formData.append('_id', loan?._id);
        formData.append('type', loan?.type.toLowerCase());

        let res = await uploadFileRequest(url, headers, formData);
        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        } else {
            dispatch(show({
                type : "success",
                state : true,
                message : res?.message
            
            }))
            setTimeout(() => {
                window.location.reload();
            },1500)
        }
        
        setProcess(false);
    }

    return (
        <div className='space-y-4'>
            <h4 className='text-md text-display font-medium leading-6'>
                File Uploads
            </h4>

            <div className='rounded-md'>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        <table className='min-w-full'>
                            <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                <tr>
                                    <th scope="col" className='px-4 py-3'>Type of files</th>
                                    <th scope="col" className='px-4 py-3'>Select file</th>
                                    <th scope="col" className='px-4 py-3'>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr className="px-4 py-6 border-b text-gray-500 hover:text-gray-700 hover:bg-gray-50">
                                    <td className='px-4 py-3'>
                                        <select name="file-upload" id="file-upload" className="form-input" onChange={(e) => setFileType(e.target.value)}>
                                            <option value="" hidden>-- select an option --</option>
                                            {loan?.state !== "approved" ? (
                                                <>
                                                <option value="incDoc">Incorporation Document</option>
                                                <option value="solePropId">Sole Propietorship ID</option>
                                                <option value="bankDoc">Bank Document</option>
                                                <option value="momo">Mobile Money</option>
                                                <option value="id">ID Card</option>
                                                <option value="residence">Proof of Residence</option>
                                                <option value="groupAgree">Group Agreement</option>
                                                <option value="other">Other Document</option>
                                                </>
                                            ) : (
                                                <>
                                                <option value="facility-letter-signed">Facility Letter (signed)</option>
                                                <option value="standOrder">Standing Order</option>
                                                <option value="other">Other</option>
                                                </>
                                            )}
                                        </select>
                                    </td>
                                    <td className='px-4 py-3'>
                                        <input type="file" name="file-upload-item" id="file-upload-item" className='form-input pt-1.5' onChange={handleFileChange} />
                                    </td>
                                    <td className='px-4 py-3'>
                                        <Button 
                                            btnText="Upload"
                                            btnType="button"
                                            btnClasses="form-button mt-2"
                                            processing={process}
                                            btnFunction={uploadRequest}
                                            disabled={btnState}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileUpload