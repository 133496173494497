import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline"

function Pagination({ nPages, currentPage, setCurrentPage, length, handlePagination }) {

    // const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

    const goToNextPage = () => {
        if(currentPage !== nPages) {
            setCurrentPage(currentPage + 1)
            handlePagination(currentPage + 1)
        }
    }

    const goToPrevPage = () => {
        if(currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            handlePagination(currentPage - 1)
        }
    }

    const goToPageClicked = (page) => {
        setCurrentPage(page)
        handlePagination(page)
    }

    return (
        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-4">
            {/* mobile pagination */}
            <div className="flex flex-1 justify-between sm:hidden">
                <span className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer ${currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "text-slate-700 hover:bg-gray-50"}`} onClick={goToPrevPage} disabled={currentPage === 1 && true}>
                    Previous
                </span>
                <span className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 cursor-pointer ${currentPage === pageNumbers[pageNumbers.length - 1] ? "text-gray-400 cursor-not-allowed" : "text-slate-700 hover:bg-gray-50"}`} onClick={goToNextPage} disabled={pageNumbers[pageNumbers.length - 1] && true}>
                    Next
                </span>
            </div>
            {/* mobile pagination */}

            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">

                <div>
                    {nPages > 1 ? 
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{currentPage > 1 ? currentPage * 10 - 10 + 1 : 1}</span> to <span className="font-medium">{(currentPage > 1 && (currentPage * 10 > length)) ? length : currentPage > 1 ? currentPage * 10 : 10}</span> of{' '}
                        <span className="font-medium">{length}</span> results
                    </p>
                    : 
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{length}</span> result{length > 1 ? `s` : ``}
                    </p>
                    }
                </div>

                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <span className={`relative inline-flex items-center rounded-l-md px-4 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 ${currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "text-slate-700 hover:bg-gray-50 cursor-pointer"}`} onClick={goToPrevPage} disabled={currentPage === 1 && true}>
                            <span className="sr-only">Previous</span>
                            <ArrowLongLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" /> <span className='text-sm'>Previous</span>
                        </span>

                        {pageNumbers.length > 7 ? (
                            <>
                                <span aria-current="page" className={
                                    `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === 1 ? 
                                        "z-10 bg-light-blue text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : 
                                        "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}`} onClick={() => goToPageClicked(1)}>
                                    1
                                </span>
                                <span aria-current="page" className={
                                    `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === 2 ? 
                                        "z-10 bg-light-blue text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : 
                                        "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}`} onClick={() => goToPageClicked(2)}>
                                    2
                                </span>
                                <span aria-current="page" className={
                                    `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === 3 ? 
                                        "z-10 bg-light-blue text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : 
                                        "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}`} onClick={() => goToPageClicked(3)}>
                                    3
                                </span>
                                <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                                    ...
                                </span>
                                <span aria-current="page" className={
                                    `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === pageNumbers[pageNumbers.length - 3] ? 
                                        "z-10 bg-light-blue text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : 
                                        "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}`} onClick={() => goToPageClicked(pageNumbers[pageNumbers.length - 3])}>
                                    {pageNumbers[pageNumbers.length - 3]}
                                </span>
                                <span aria-current="page" className={
                                    `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === pageNumbers[pageNumbers.length - 2] ? 
                                        "z-10 bg-light-blue text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : 
                                        "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}`} onClick={() => goToPageClicked(pageNumbers[pageNumbers.length - 2])}>
                                    {pageNumbers[pageNumbers.length - 2]}
                                </span>
                                <span aria-current="page" className={
                                    `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === pageNumbers[pageNumbers.length - 1] ? 
                                        "z-10 bg-light-blue text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : 
                                        "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}`} onClick={() => goToPageClicked(pageNumbers[pageNumbers.length - 1])}>
                                    {pageNumbers[pageNumbers.length - 1]}
                                </span>
                            </>
                        ) : (
                            pageNumbers?.map((item) => (
                                <span key={item} aria-current="page" className={
                                    `relative inline-flex items-center px-4 py-2 text-sm font-semibold cursor-pointer ${currentPage === item ? 
                                        "z-10 bg-light-blue text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" : 
                                        "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}`} onClick={() => goToPageClicked(item)}>
                                    {item}
                                </span>
                            ))
                        )}

                        <span className={`relative inline-flex items-center rounded-r-md px-4 py-2 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0 ${currentPage === pageNumbers[pageNumbers.length - 1] ? "text-gray-400 cursor-not-allowed" : "text-slate-700 hover:bg-gray-50 cursor-pointer"}`} onClick={goToNextPage} disabled={pageNumbers[pageNumbers.length - 1] && true}>
                            <span className="sr-only">Next</span>
                            <ArrowLongRightIcon className="h-5 w-5 mr-2" aria-hidden="true" /> <span className='text-sm'>Next</span>
                        </span>
                    </nav>
                </div>

            </div>
        </div>
    )
}

export default Pagination