import { Fragment, useState } from 'react'
import { Dialog, Transition, RadioGroup } from '@headlessui/react'
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { FormInput, Button } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';
import { authStaff, dateToHumanFormat } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

function PaymentItem({ item }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [transID, setTransID] = useState("");
    const [date, setDate] = useState("");
    const [amount, setAmount] = useState("");
    const [phone, setPhone] = useState("");
    const [penalRateAmount, setPenalRateAmount] = useState("");
    const [remarks, setRemarks] = useState("");
    const [process, setProcess] = useState(false);
    const [loans, setLoans] = useState([]);
    const [selectedLoan, setSelectedLoan] = useState();

    const setSelected = (selected) => {
        setSelectedLoan(selected);
    };

    function openModal() {
        setIsOpen(true)
    }

    function closeModal() {
        setIsOpen(false)
    }

    const verifyLoan = async(e) => {
        e.preventDefault();
        setProcess(true);

        let params = { state : "active", phone };
        let url = `/loans/staff/search-loans`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "get", headers, params, {});
        setProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        setLoans(res);
    }

    const submitRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let data = { 
            _id : selectedLoan, 
            msgId : item?._id, 
            transID : transID.replace(/[^a-zA-Z0-9]/g, ''), 
            date, 
            amount, 
            penalRateAmount, 
            remarks 
        };

        let url = `/payments/staff/match-payment`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `${res?.message}. Reloading...`
        }))

        setTimeout(() => {
            window.location.reload();
        }, 2000)

    }

    return (
        <tr key={item?._id} className="px-4 py-6 border-b hover:bg-gray-100">
            <td className='px-4 py-3'>
                {dateToHumanFormat(item?.date)}
            </td>
            <td className='px-4 py-3'>
                {item?.msg}
            </td>
            <td className={`px-4 py-3 font-medium ${!item?.unmatched ? 'text-emerald-500' : ''}`}>
                {item?.unmatched ? 'Unmatched' : 'Matched'}
            </td>
            <td className='px-4 py-3'>
                <EllipsisHorizontalCircleIcon 
                    className={`w-6 h-6 hover:scale-125 transition-all duration-75 ease-in-out ${!item?.unmatched ? 'hidden' : ''}`} 
                    role='button'
                    onClick={openModal}
                />
            </td>
            <td className='hidden'>

                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-md md:max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                    Transaction Information ({dateToHumanFormat(item?.date)})
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500 bg-gray-100 p-3 rounded-md mb-6">
                                        {item.msg}
                                    </p>

                                    <form className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                        <FormInput 
                                            label={"Transaction ID"}
                                            type={"text"}
                                            name={"transID"}
                                            id={"transID"}
                                            value={transID}
                                            setValue={setTransID}
                                        />

                                        <div>
                                            <label htmlFor="date" className='form-label'>Transaction date:</label>
                                            <input type="date" name="date" id="date" className='form-input' autoComplete="date" max={new Date().toISOString().split('T')[0]} required value={date} onChange={(e) => setDate(e.target.value)} />
                                        </div>

                                        <FormInput 
                                            label={"Amount"}
                                            type={"number"}
                                            name={"amount"}
                                            id={"amount"}
                                            value={amount}
                                            setValue={setAmount}
                                        />

                                        <FormInput 
                                            label={"Client's Phone Number"}
                                            type={"text"}
                                            name={"phone"}
                                            id={"phone"}
                                            value={phone}
                                            setValue={setPhone}
                                        />

                                        <FormInput 
                                            label={"Penal Rate Amount (0 if none)"}
                                            type={"number"}
                                            name={"penalRateAmount"}
                                            id={"penalRateAmount"}
                                            value={penalRateAmount}
                                            setValue={setPenalRateAmount}
                                        />

                                        <FormInput 
                                            label={"Remarks"}
                                            type={"text"}
                                            name={"remarks"}
                                            id={"remarks"}
                                            value={remarks}
                                            setValue={setRemarks}
                                        />

                                        {loans && loans?.length > 0 && (
                                            <div className='col-span-full'>
                                                <h4 className='my-3'>Select loan</h4>
                                                <RadioGroup onChange={setSelected}>
                                                {loans.map((loan) => (
                                                    <RadioGroup.Option key={loan._id} value={loan._id}
                                                    className={({ active, checked }) => `${ active ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'  : ''}
                                                    ${checked ? 'bg-light-blue text-white' : 'bg-white'} relative flex cursor-pointer rounded-lg px-5 py-4 shadow-sm border border-slate-200 hover:border-light-blue transition duration-100 delay-75 mb-2`}
                                                    >
                                                        {({ active, checked }) => (
                                                            <>
                                                                <div className='flex items-center justify-between w-full'>
                                                                    <div className='flex items-center'>
                                                                        <div className='text-sm'>
                                                                            <RadioGroup.Label as="p" className={`font-medium mb-1 ${checked ? "text-white" : "text-gray-900"}`}>
                                                                                {`${loan?.signedName}`}{' '}
                                                                            <span aria-hidden="true">&middot;</span>{' '}{loan.type}
                                                                            </RadioGroup.Label>
                                                                            <RadioGroup.Description as="span" className={`text-sm inline text-gold-500 ${checked ? "text-sky-100" : "text-gray-500"}`}>
                                                                            <span>
                                                                            {`Amount requested: ${loan?.amountReq}gh`}
                                                                            </span>{' '}
                                                                            <span aria-hidden="true">&middot;</span>{' '}
                                                                            <span>{`Repayment Amount: ${loan?.accountInfo?.premium}gh`}</span>
                                                                            </RadioGroup.Description>
                                                                        </div>
                                                                    </div>
                                                                    {checked && (
                                                                        <div className="shrink-0 text-light-blue bg-white rounded-full p-1">
                                                                            <CheckIcon className="h-4 w-4" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                                </RadioGroup>
                                            </div>
                                        )}

                                        <div className='mt-6 col-span-full'>
                                            {(loans && loans?.length > 0) ? (
                                                <Button 
                                                    btnText={"Submit"}
                                                    btnClasses={"form-button-default"}
                                                    btnType={"submit"}
                                                    btnFunction={submitRequest}
                                                    processing={process}
                                                />
                                            ) : (
                                                <Button 
                                                    btnText={"Verify Loan"}
                                                    btnClasses={"form-button-default"}
                                                    btnType={"submit"}
                                                    btnFunction={verifyLoan}
                                                    processing={process}
                                                />
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </div>
                    </Dialog>
                </Transition>
            </td>
        </tr>
    )
}

export default PaymentItem