import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectAdminRole } from '../slices/staffSlice';
import { fetchRequests } from '../Requests/getRequests';
import { show } from '../slices/toastSlice';
import { authStaff, loanStates } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

function useLoans({ loanType, state, active, completed, rejected, endpoint, page }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const adminRole = useSelector(selectAdminRole);
    const [isLoading, setIsLoading] = useState(true);
    const [loans, setLoans] = useState([]);
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        let unsubscribed = false;

        const fetchLoans = async() => {
            setIsLoading(true);

            let headers = authStaff(accessToken);

            let url = active ? `/loans/staff/active-loans` : 
                completed ? `/loans/staff/completed-loans` : 
                rejected ? `/loans/staff/rejected-loans` :
                `/loans/staff/${endpoint}`;
            
            let params = active ? { state : loanStates.active, page : page || 1 } : 
                completed ? { state : loanStates.completed, page : page || 1 } : 
                rejected ? { state : loanStates.rejected, page : page || 1 } :
                endpoint === "fetchLoans" ? { loanType : loanType, adminRole : adminRole, page : page || 1 } : 
                { loanType : loanType, state : state, page : page || 1 };

            let res = await fetchRequests(url, "GET", headers, params, {});
            setIsLoading(false);

            if(res?.code === 401){
                // clear local storage and redirect to login
                localStorage.clear();
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : res?.err
                }))
                return;
            }

            if(!unsubscribed){
                setLoans(res.loans);
                setMetadata(res.metadata);
            }
        }

        fetchLoans();

        return () => {
            unsubscribed = true;
        }
    }, [dispatch, navigate, accessToken, setIsLoading, setLoans, setMetadata, loanType, state, active, completed, rejected, endpoint, adminRole, page])

    return { isLoading, loans, metadata }
}

export default useLoans