import { useState } from "react";
import { AutoDisburse, ManualDisburse } from "../../../components";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { loanTypesOld } from "../../../utils/constants";
import PreApproval from "./PreApproval";

function Disburse({ loan }) {

    const [confirmedClient, setConfirmedClient] = useState(false);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    if(loan?.type === loanTypesOld.cediscredit && !confirmedClient){
        return (
            <PreApproval 
                loan={loan} 
                setConfirmedClient={setConfirmedClient}
            />
        )
    }

    return (
        <>
        <TabGroup>
            <TabList className="flex space-x-1 rounded-xl p-1">
                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                    Manual Disbursement
                </Tab>
                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white', selected ? 'bg-light-blue shadow' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                    Auto Disbursement (MTN)
                </Tab>
            </TabList>

            <TabPanels className="mt-2">
                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                    <ManualDisburse loan={loan} />
                </TabPanel>
                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                    <AutoDisburse loan={loan} />  
                </TabPanel>
            </TabPanels>
        </TabGroup>

        </>
    )
}

export default Disburse