import { useState } from 'react';
import { Button, FormInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectStaff } from "../../../slices/staffSlice";
import { fetchRequests } from '../../../Requests/getRequests';
import { authStaff, dateToHumanFormat, roundNum } from "../../../utils/constants";
import { useNavigate } from 'react-router-dom';
import { show } from '../../../slices/toastSlice';

function AutoDisburse({ loan }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const staff = useSelector(selectStaff);
    const navigate = useNavigate();

    const [repaymentDate, setRepaymentDate] = useState("");
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(false);

    const principalAmount = loan?.amount;
    const processingFee = loan?.accountInfo?.processingFee;
    const insuranceFee = loan?.accountInfo?.insuranceFee;
    // const networkProcessing = roundNum(((0.5 / 100) * loan?.amount), 2); 
    const amountToDisburse = roundNum((loan?.accountInfo?.amountGiven), 2);

    const handleDisbursement = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let url = `/payments/staff/initiate-payment`;
        let headers = authStaff(accessToken);

        let data = {
            _id : loan?._id,
            type : loan?.type.toLowerCase(),
            state : loan?.state,
            repaymentDate
        }

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcessing(false);
        
        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            setError(`Funds could not be disbursed at this moment, please try again in a few minutes`);
            return;
        } 

        dispatch(show({
            type : "success",
            state : true,
            message : `Funds disbursed successfully.`
        }))
        setTimeout(() => {
            navigate("/active-loans")
        },1500)
    }

    return (
        <div className='rounded-md'>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table className='min-w-full'>
                        <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                            <tr>
                                <th scope="col" className='px-4 py-3'>
                                    Auto Disbursement
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr className="px-4 py-6 border-b text-gray-500">
                                <td className='px-4 py-3'>
                                    <form>
                                        <div className='grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-6'>
                                            <div>
                                                <label htmlFor="customerName" className='form-label'>Name of Customer</label>
                                                <FormInput 
                                                    type="text"
                                                    name="customerName"
                                                    id="customerName"
                                                    required
                                                    disabled={true}
                                                    value={`${loan?.client?.otherNames} ${loan?.client?.surname}`}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="mobileNumber" className='form-label'>Mobile Number</label>
                                                <FormInput 
                                                    type="number"
                                                    name="mobileNumber"
                                                    id="mobileNumber"
                                                    required
                                                    disabled={true}
                                                    value={loan?.phone}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="mobileMoneyWallet" className='form-label'>Mobile Money Network</label>
                                                <select name="file-upload" id="file-upload" className="form-input disabled:opacity-75 disabled:bg-gray-200" disabled>
                                                    <option defaultValue={"MTN"}>MTN</option>
                                                    <option value="MTN">MTN</option>
                                                    <option value="Vodafone">Vodafone</option>
                                                    <option value="Airtel - Tigo">Airtel - Tigo</option>
                                                </select>
                                            </div>

                                            <div>
                                                <label htmlFor="principalAmount" className='form-label'>Approved Amount</label>
                                                <FormInput 
                                                    type="number"
                                                    name="principalAmount"
                                                    id="principalAmount"
                                                    required
                                                    disabled={true}
                                                    value={principalAmount}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="processingFee" className='form-label'>Processing Fee</label>
                                                <FormInput 
                                                    type="number"
                                                    name="processingFee"
                                                    id="processingFee"
                                                    required
                                                    disabled={true}
                                                    value={processingFee}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="insuranceFee" className='form-label'>Insurance Fee</label>
                                                <FormInput 
                                                    type="number"
                                                    name="insuranceFee"
                                                    id="insuranceFee"
                                                    required
                                                    disabled={true}
                                                    value={insuranceFee}
                                                />
                                            </div>

                                            {/* <div>
                                                <label htmlFor="networkFee" className='form-label'>Network Processing Fee</label>
                                                <FormInput 
                                                    type="number"
                                                    name="networkFee"
                                                    id="networkFee"
                                                    required
                                                    disabled={true}
                                                    value={networkProcessing}
                                                />
                                            </div> */}

                                            <div>
                                                <label htmlFor="amountToDisburse" className='form-label'>Amount to Disburse</label>
                                                <FormInput 
                                                    type="number"
                                                    name="amountToDisburse"
                                                    id="amountToDisburse"
                                                    required
                                                    disabled={true}
                                                    value={amountToDisburse}
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="repaymentDate" className='form-label'>Repayment Start Date</label>
                                                <input 
                                                    type="date"
                                                    name={"repaymentDate"}
                                                    id={"repaymentDate"}
                                                    className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                                                    required={false}
                                                    value={repaymentDate}
                                                    onChange={e => setRepaymentDate(e.target.value)}
                                                    min={new Date().toISOString().split('T')[0]}
                                                />
                                            </div>
                                        </div>

                                        <div className='mt-5 bg-yellow-200 p-4 rounded-md text-yellow-700'>
                                            If the loan is disbursed today without "Repayment Start Date" being altered, the repayment start date would be <strong>{dateToHumanFormat(loan?.startDate)}</strong> and would end <strong>{dateToHumanFormat(loan?.endDate)}</strong>. If the client has a "Standing Order" and this was indicated in the loan application, input the date the standing order would be activated as the "Repayment Start Date". The addictinal five (5) days would be added to the repayment end date.
                                        </div>

                                        <div className={`inline-flex items-center space-x-4 mt-6 ${!staff?.isTreasurer && 'hidden'}`}>
                                            <Button 
                                                btnText={"Disburse"}
                                                btnType="submit"
                                                btnClasses={"form-button w-auto"}
                                                disabled={processing}
                                                processing={processing}
                                                btnFunction={handleDisbursement}
                                            />
                                        </div>
                                    </form>

                                    {error && 
                                        <div className='w-100 p-3 bg-red-200 text-red-700 mb-4 rounded-md mt-4'>
                                            {error}
                                        </div>
                                    }

                                    {/* {disburse && 
                                        <DisburseNotify 
                                            response={disbursed}
                                        />
                                    } */}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AutoDisburse