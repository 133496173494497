import { useState, useEffect } from 'react'
import { DisburseSum, LoansSum, RepaymentSum } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from "../../slices/staffSlice";
import { authStaff } from '../../utils/constants';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Summary() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const [disburseData, setDisbursedData] = useState({});
    const [repayData, setRepayData] = useState({});
    const [diffLoans, setDiffLoans] = useState({
        prev : 0,
        current : 0
    });
    const [days, setDays] = useState({
        firstDay : null,
        lastDay : null
    });
    
    useEffect(() => {
        let unsubscribed = false;

        const getLoans = async() => {

            let url = `/loans/staff/stats`;
            let headers = authStaff(accessToken);

            let params = {
                date : new Date().toISOString().split("T")[0],
                disbursed : true,
                incoming : true
            }

            let res = await fetchRequests(url, "GET", headers, params, {});
            
            // output error if any
            if(res?.code === 401){ 
                // clear local storage and redirect to login
                localStorage.clear();
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : res.err
                }))
                return;
            }

            if(!unsubscribed){
                setDisbursedData({
                    prevTotal : res?.totalDisbursedPrevDay,
                    todayTotal : res?.totalDisbursed
                });

                setDiffLoans({
                    prev : res?.totalIncomingPrevDay,
                    current : res?.totalIncoming
                })

                setRepayData({
                    todayTotal : res?.totalRepays
                });

                setDays({
                    firstDay : res.firstDay,
                    lastDay : res.lastDay
                })
            }

        }

        getLoans();

        return () => {
            unsubscribed = true
        }
    }, [dispatch, navigate, accessToken])

    return (
        <section className='section-container md:py-6 py-0'>
            <h3 className='text-3xl font-medium leading-6 text-display mb-5'>Dashboard</h3>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* incoming loans */}
                <LoansSum 
                    diffLoans={diffLoans}
                    days={days}
                />
                {/* incoming loans */}

                {/* disbursed loans */}
                <DisburseSum 
                    disburseData={disburseData}
                    days={days}
                />
                {/* disbursed loans */}

                {/* repayment amounts */}
                <RepaymentSum 
                    repayData={repayData}
                    days={days}
                />
                {/* repayment amounts */}
            </div>
        </section>
    )
}

export default Summary