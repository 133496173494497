import { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from "../../../components";
import { authStaff, getFirstFiveDaysOfMonth, getAllDates, repaymentAmtLimit } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { fetchRequests } from '../../../Requests/getRequests';
import { useNavigate } from 'react-router-dom';
import { show } from '../../../slices/toastSlice';

function Repay({ isOpen, closeModal, loan }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [transactionType, setTranasctionType] = useState('repay');
    const [transactionID, setTransactionID] = useState('');
    const [transactionDate, setTransactionDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [amount, setAmount] = useState('');
    const [penalRateAmount, setPenalRateAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [process, setProcess] = useState(false);

    const handleRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        if(amount >= repaymentAmtLimit){
            dispatch(show({
                type : "error",
                state : true,
                message : "Amount cannot be more than the repayment limit of 30,000"
            }))
            setProcess(false);
            return;
        }

        let data = {
            _id : loan?._id,
            type : loan?.type.toLowerCase(),
            transactionType,
            transactionID : transactionID.replace(/[^a-zA-Z0-9]/g, ''),
            transactionDate,
            amount,
            penalRateAmount,
            remarks
        }

        let url = `/loans/staff/transactions`;
        const headers = authStaff(accessToken);

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcess(false);

        if(res.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        if(res?.path){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.message
            }))
            navigate('/completed-loans');
            return;
        }

        if(res?.message){
            dispatch(show({
                type : "success",
                state : true,
                message : res?.message
            }))
        }
        
        // reset form
        setTranasctionType('repay');
        setTransactionID('');
        setTransactionDate('');
        setAmount('');

        // refresh page
        setTimeout(() => {
            window.location.reload();
        }, 1500)
    }

    useEffect(() => {   

        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let today = currentDate.getDate();
        let dates = getFirstFiveDaysOfMonth(year, month);
        let allDates = getAllDates(dates);

        if(allDates.includes(today)){
            setMinDate(new Date(year, month - 1, 1).toISOString().split("T")[0]);
            setMaxDate(currentDate.toISOString().split("T")[0]);
        } else {
            // setMinDate(currentDate.toISOString().split("T")[0]);
            setMinDate(new Date(year, month, 1).toISOString().split("T")[0]);
            setMaxDate(currentDate.toISOString().split("T")[0]);
        }

    }, [setMinDate, setMaxDate])

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-md md:max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900 mb-2">
                                    Make Repayment
                                </Dialog.Title>

                                <form className='grid grid-cols-1 md:grid-cols-3 gap-4 rounded-md py-4' onSubmit={handleRequest}>

                                    <div>
                                        <label htmlFor="transactionType" className='form-label'>Transaction type:</label>
                                        <select className='form-input' id='transactionType' name='transactionType' required onChange={(e) => setTranasctionType(e.target.value)} defaultValue={transactionType}>
                                            <option value="" disabled>Select transaction type</option>
                                            <option value="repay">Repayment</option>
                                            <option value="writeoff">Write-off</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="transactionID" className='form-label'>Transaction ID:</label>
                                        <input type="text" name="transactionID" id="transactionID" className='form-input' required value={transactionID} onChange={(e) => setTransactionID(e.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="transactionDate" className='form-label'>Transaction date:</label>
                                        <input type="date" name="transactionDate" id="transactionDate" className='form-input' min={minDate} max={maxDate} required value={transactionDate} onChange={(e) => setTransactionDate(e.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="amount" className='form-label'>Amount:</label>
                                        <input type="number" name="amount" id="amount" className='form-input' required value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="penalRateAmount" className='form-label'>Penal Rate Amount:</label>
                                        <input type="number" name="penalRateAmount" id="penalRateAmount" className='form-input' required value={penalRateAmount} onChange={(e) => setPenalRateAmount(e.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="remarks">Remarks:</label>
                                        <input type="text" name="remarks" id="remarks" className='form-input' required value={remarks} onChange={(e) => setRemarks(e.target.value)} placeholder='Indicate if transaction is past or present' />
                                    </div>

                                    <div className='col-span-full space-x-4'>
                                        <Button 
                                            btnText={"Submit"}
                                            btnClasses={"form-button w-auto px-6"}
                                            btnType="submit"
                                            processing={process}
                                        />
                                    </div>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>

            </Dialog>
        </Transition>
    )
}

export default Repay