import { useState } from "react";
import { getLoanBalance } from "../../utils/constants";
import { cediSign } from "../../utils/constants";
import { Button, Reversal, Repay } from "../../components";

function LoanState({ loan }) {
    
    const payments = loan?.ledgerRows?.filter(item => item.capital === null || item.capital === undefined) || [];
    const outstandingBalance = loan?.accountInfo ? (loan.accountInfo.totalPayment - getLoanBalance(payments)).toFixed(2) : null;

    // for repayment modal
    const [repayIsOpen, setRepayIsOpen] = useState(false);
    const repayModal = () => {
        setRepayIsOpen(!repayIsOpen);
    }

    const closeRepayModal = () => {
        setRepayIsOpen(!repayIsOpen)
    }

    // for reversal modal
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    const closeModal = () => {
        setIsOpen(!isOpen);
    }

    if(outstandingBalance === null) return null;

    return (
        <>
        {outstandingBalance > 0 ? (
        <div className='flex justify-center flex-col space-y-3 bg-gradient-to-r from-gray-50 to-transparent px-0 pb-4 sm:p-4 rounded-md shadow-sm'>
            <div className="flex items-start sm:items-center flex-col sm:flex-row justify-between">
                <div className="mb-4 sm:mb-0">
                    <h6 className="mb-1 text-gold-700 font-medium">Balance</h6>
                    <span className='tabular-nums text-4xl font-semibold'>
                        {cediSign} {outstandingBalance}
                    </span>
                    <span className="ml-2 text-xs text-gray-500 hidden sm:inline">
                        <strong>{payments?.length}</strong> payments made out of <strong>{loan?.accountInfo?.numPayments}</strong> made.
                    </span>
                </div>

                <div className="inline-flex items-center space-x-2">
                    <Button 
                        btnText={"Make Payment"}
                        btnClasses={"form-button w-auto px-4"}
                        btnType="button"
                        btnFunction={repayModal}
                    />

                    <Button 
                        btnText={"Reversal Request"}
                        btnClasses={"form-button-default w-auto px-4"}
                        btnType="button"
                        btnFunction={openModal}
                    />
                </div>
            </div>

            {(loan?.state === "active" && loan?.daysInDelinq && loan?.daysInDelinq > 0) ? (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Notice:</strong>
                    <span className="block sm:inline"> Client is <span className='font-bold'>{loan?.daysInDelinq}</span> days behind in payments. Please alert client to make payment to avoid penalties.</span>
                </div>
            ) : null}
        </div>
        ) : (
        <div className='flex items-center justify-center bg-emerald-200 px-3 py-4 rounded-md text-sm'>
            <span>
                🎉 🎉 <strong>Congratulations, Loan fully paid off</strong> 🎉 🎉
            </span>
        </div>
        )}
        <div className='hidden'>
            <Repay 
                isOpen={repayIsOpen}
                closeModal={closeRepayModal}
                loan={loan}
            />

            <Reversal 
                isOpen={isOpen}
                closeModal={closeModal}
                loanId={loan?.loanId}
                loanType={loan?.type}
                phone={loan?.phone}
                client={loan?.client?.otherNames + ' ' + loan?.client?.surname}
            />
        </div>
        </>
    )
}

export default LoanState