import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';
import { authStaff, loanFilters } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { Topbar, Sidebar, LoansTable, Loader, Filter } from "../../components";

function Index() {

    const [loans, setLoans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [metadata, setMetadata] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const [isPagLoading, setIsPagLoading] = useState(false);
    const [pagLoans, setPagLoans] = useState(null);
    const [pagMetadata, setPagMetadata] = useState(null);
    const [filteredLoanType, setFilteredLoanType] = useState(null);

    let loadingInc = isPagLoading ? isPagLoading : loading;

    // create a function to handle pagination fetching
    const handlePagination = async(page) => {
        setIsPagLoading(true);

        let headers = authStaff(accessToken);
        let url = `/loans/staff/approvedLoans`;
        let params = { 
            loanType : filteredLoanType ? filteredLoanType : loanFilters[0].value, 
            state : "approved",
            page : page || 1 
        };

        let res = await fetchRequests(url, "GET", headers, params, {});
        setIsPagLoading(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
            navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        // set the loans and metadata to the paginated loans and metadata
        setPagLoans(res.loans);
        setPagMetadata(res.metadata);
    }

    useEffect(() => {
        document.title = `Signed Facility Applications - ${process.env.REACT_APP_SITE_NAME}`;
    },[])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>

                <section className='section-container'>

                    <div className='flex items-center justify-between mb-10'>
                        <h3 className='text-lg lg:text-3xl font-medium leading-6 text-display inline-flex items-center'>
                            Approved Loans {loadingInc && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                        </h3>

                        <Filter 
                            endpoint="approvedLoans"
                            state="approved"
                            setLoading={setLoading}
                            setLoans={setLoans}
                            setMetadata={setMetadata}
                            setFilteredLoanType={setFilteredLoanType}
                        />
                    </div>

                    <LoansTable 
                        loans={pagLoans ? pagLoans : loans} 
                        metadata={pagMetadata ? pagMetadata : metadata}
                        handlePagination={handlePagination}
                    />

                </section>

            </main>

        </div>
    )
}

export default Index