import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Topbar, Sidebar, Button, AccountIndex, ApplicationIndex, CreditIndex, FilesIndex, Loader, FormTextArea, BreadCrump, Flag, FlagBanner } from "../../components";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { fetchRequests } from "../../Requests/getRequests";
import { useSelector, useDispatch } from "react-redux";
import { selectAccessToken, selectAdminRole } from "../../slices/staffSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoles, authStaff } from "../../utils/constants";
import { show } from '../../slices/toastSlice';
import { useLoan } from '../../hooks';
import { loanRejectionReasons } from '../../utils/loanConstants';
import { FlagIcon } from '@heroicons/react/24/solid';


function Index() {

    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const adminRole = useSelector(selectAdminRole);
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const loanType = path[1];
    const loanId = path[2];
    const [comments, setComments] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [approveProcess, setApproveProcess] = useState(false);
    const [disapproveProcess, setDisapproveProcess] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [reason, setReason] = useState("");

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    // flag modal functions
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isFlagModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    }
    const isFlagModalClosed = () => {
        setIsModalOpen(!isModalOpen);
    }


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const { isLoading, loan } = useLoan(
        { loanId, loanType, state : null, approved : false, active : false, completed : false }
    );

    const handleSetReason = (e) => {
        setReason(e.target.value);
    }

    const approveLoanRequest = async(e) => {
        e.preventDefault();
        setApproveProcess(true);

        const form = document.getElementById("app-form");
        const data = new FormData(form);
        let newObj = {};
        for (let [key, value] of data) {
            newObj[key] = value;
        }

        let url = `/loans/staff/approveRejectLoan`;
        let headers = authStaff(accessToken);
        let body = {
            _id : loanId,
            type : loanType,
            approved : true,
            ...newObj,
            adminRole : adminRole
        }
        let res = await fetchRequests(url, "POST", headers, {}, body);
        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        } else {
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`
            }))
            setTimeout(() => {
                navigate("/loans");
            },1500)
        }

        setApproveProcess(false);
    }

    const rejectLoanRequest = async(e) => {
        e.preventDefault();
        setDisapproveProcess(true);

        const form = document.getElementById("app-form");
        const data = new FormData(form);
        let newObj = {};
        for (let [key, value] of data) {
            newObj[key] = value;
        }

        let url = `/loans/staff/rejectRejectLoan`;
        let headers = authStaff(accessToken);
        let body = {
            _id : loanId,
            type : loanType,
            approved : false,
            ...newObj,
            adminRole : adminRole,
            comments,
            reason,
            dateTime
        }
        let res = await fetchRequests(url, "POST", headers, {}, body);
        setDisapproveProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        dispatch(show({
            type : "error",
            state : true,
            message : res?.message
        }))
        setTimeout(() => {
            navigate("/loans");
        },1200)
    
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-6'>

                    {loan ? 
                        <>
                        <div className="flex items-center justify-between">
                            <BreadCrump 
                                path='/loans'
                                main="Loans"
                                sub={isLoading ? <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : `${loan?.type} - ${loan?._id}`}
                            />

                            <div className="inline-flex min-h-[42px] w-max items-center gap-0.5 overflow-hidden border border-gray-200 rounded-lg px-1.5 mb-2 text-sm divide-x">
                                <button type='button' className='inline-flex items-center px-1 py-1.5 text-gray-500 hover:bg-slate-100 rounded-md' onClick={isFlagModalOpen}>
                                    <FlagIcon className='h-5 w-5 text-red-500 ml-2' />
                                    <span className="px-2 text-red-500">
                                        Flag
                                    </span>
                                </button>
                            </div>
                        </div>

                        {loan?.clientFlagged && Object.keys(loan.clientFlagged).length > 0 && (
                            <FlagBanner 
                                clientFlagged={loan.clientFlagged}
                            />
                        )}

                        <TabGroup>
                            <TabList className="flex space-x-1 rounded-xl p-1">
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Application
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Credit Bureau
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Files
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Loan Account
                                </Tab>
                            </TabList>

                            <form id='app-form'>
                                <TabPanels className="mt-2">
                                    <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                        <ApplicationIndex loan={loan} />
                                    </TabPanel>
                                    <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                        <CreditIndex loan={loan} />
                                    </TabPanel>
                                    <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                        <FilesIndex loan={loan} />
                                    </TabPanel>
                                    <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                        <AccountIndex loan={loan} />
                                    </TabPanel>
                                </TabPanels>

                                
                                <div className='mt-6 py-4 px-2 space-y-6'>
                                    {loan?.adminStage === adminRoles.pm && (
                                        <div>
                                            <label htmlFor="overallAssessment" className='form-label'>
                                                Overall Assessment
                                            </label>
                                            <FormTextArea
                                                name="overallAssessment"
                                                id="overallAssessment"
                                                placeholder="Indicate why the loan should be approved"
                                                value={loan && loan?.overallAssessment}
                                                disabled={loan?.overallAssessment ? true : false}
                                            />
                                        </div>   
                                    )}
                                    <div>
                                        <label htmlFor="comment" className='form-label'>
                                            Comments
                                        </label>
                                        <textarea name="comment" id="comment" cols="30" rows={4} className="form-textarea" onChange={(e) => setComments(e.target.value)}></textarea>
                                    </div>

                                    <div className='inline-flex items-center justify-between mt-5 space-x-4'>
                                        <Button 
                                            btnText="Approve"
                                            btnType="button"
                                            btnClasses="form-button"
                                            processing={approveProcess}
                                            btnFunction={approveLoanRequest}
                                        />

                                        <Button 
                                            btnText="Reject"
                                            btnType="button"
                                            btnClasses="form-button-default"
                                            btnFunction={openModal}
                                        />
                                    </div>
                                </div>
                            </form>

                        </TabGroup>

                        </>
                    : 
                    <div className='w-full py-2 inline-flex justify-center items-center'>
                        <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                    </div>
                    }

                </section>
            </main>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Select Reason for Rejection
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            This loan application is being rejected for the following reason: <strong><i>selecting an option will be sent to the client!</i></strong>
                                        </p>

                                        <div className='my-4'>
                                        {loanRejectionReasons.map((reason, index) => (
                                            <div key={index} className="mt-2 inline-flex items-center mr-4">
                                                <input type="radio" name="rejectionReason" id={`rejectionReason-${index}`} value={reason.value} className="form-radio text-gray-500 h-4 w-4 accent-red-500" onChange={handleSetReason} />
                                                <label htmlFor={`rejectionReason-${index}`} className="ml-2 text-sm text-gray-500">{reason.text}</label>
                                            </div>
                                        ))}
                                        </div>

                                        <div className="my-4">
                                            <label htmlFor="rejectedComment" className='form-label'>
                                                Comments
                                            </label>   

                                            <textarea name="rejectedComment" id="rejectedComment" cols="30" rows={4} className="form-textarea" onChange={(e) => setComments(e.target.value)}></textarea> 
                                        </div>

                                        <div className="my-4">
                                            <label htmlFor="dateTime" className="form-label">
                                                Date and Time of Call to Client
                                            </label>

                                            <input type="datetime-local" name="dateTime" id="dateTime" className='form-input' onChange={(e) => setDateTime(e.target.value)} required />
                                        </div>
                                    </div>

                                    <div className="mt-4 space-x-2">
                                        <Button 
                                            btnText="Reject"
                                            btnType="button"
                                            btnClasses="danger-button"
                                            btnFunction={rejectLoanRequest}
                                            processing={disapproveProcess}
                                        />

                                        <Button 
                                            btnText="Cancel"
                                            btnType="button"
                                            btnClasses="cancel-button"
                                            btnFunction={closeModal}
                                        />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>

                </Dialog>
            </Transition>

            <Flag 
                isOpen={isModalOpen}
                isClosed={isFlagModalClosed}
                client={loan?.client}
            />
        </div>
    )

}

export default Index;