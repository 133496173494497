import { useState } from 'react';
import { authStaff } from '../../utils/constants';
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../slices/staffSlice";
import { fetchRequests } from '../../Requests/getRequests';
import { Loader } from "../../components";
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Suspend({ _id, status }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const clientId = useSelector(selectStaffId);
    const [supendLoader, setSuspendLoader] = useState(false);

    const suspendAccount = async() => {
        setSuspendLoader(true);
        let verifyUrl = `/auth/staff/client/disable`;
        let headers = authStaff(accessToken);
        let data = {
            _id : _id
        }

        let res = await fetchRequests(verifyUrl, "PUT", headers, {}, data);
        setSuspendLoader(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
        } 
        
        if(res?.message){
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`
            }))

            setTimeout(() => {
                window.location.reload();
            }, 1500)
        } 
    }

    return (
        <span 
            className={`action-buttons ${status ? 
                'bg-emerald-100 text-emerald-700 hover:bg-emerald-200 hover:text-emerald-700 border-0' 
                : 
                'bg-red-100 text-red-700 hover:bg-red-200 hover:text-red-700 border-0'}`
            } 
            onClick={suspendAccount}
        >
            {supendLoader ? 
                <Loader color="text-gray-700" size="5" /> 
                : 
                status ? 
                    "Reactivate Account" 
                    : 
                    "Suspend Account"
            }
        </span>
    )
}

export default Suspend