import { useEffect, useState } from "react";
import { Topbar, Sidebar, Loader, BreadCrump } from "../../components";
import { fetchRequests } from "../../Requests/getRequests";
import { useDispatch } from "react-redux";
import { show } from '../../slices/toastSlice';
import { authStaff } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../../slices/staffSlice";
import { useLocation, useNavigate } from "react-router-dom";
// import ClientTable from "./ClientTable";
// import { FlagIcon } from '@heroicons/react/24/solid';

function ClientEdit() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const clientId = location.pathname.split("/")[3];
    const [client, setClient] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getUserDetails = async(clientId) => {
            let clientUrl = `/auth/staff/client/find`;
            let headers = authStaff(accessToken);
            let params = {
                _id : clientId
            }

            let res = await fetchRequests(clientUrl, "GET", headers, params, {});
            setIsLoading(false);

            if(res?.code === 401){
                // clear local storage and redirect to login
                localStorage.clear();
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }
            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : `${res?.err}`
                }))
                return;
            } 

            setClient(res);
        }

        getUserDetails(clientId);
    },[accessToken, clientId, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content pb-36'>
                <section className='section-container space-y-6'> 

                    {client ? 
                        <BreadCrump 
                            path='/'
                            main="Dashboard"
                            sub={isLoading ? 
                                <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : 
                                `${client?.otherNames} ${client?.surname}'s Details - ${client?.isVerified ? 'Verified' : 'Not Verified'}`
                            }
                        />
                    : 
                    <div className='w-full py-2 inline-flex justify-center items-center'>
                        <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                    </div>
                    }

                </section>
            </main>

        </div>
    )
}

export default ClientEdit