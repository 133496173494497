import { useState } from "react";
import { Pagination } from "../../../components";
import { dateToHumanFormat, roundNum } from "../../../utils/constants";

function Transactions({ loan }) {

    const transactions = loan?.ledgerRows;

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = transactions?.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(transactions?.length / recordsPerPage);

    return (
        <div className='rounded-md'>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                    <table className='min-w-full'>
                        <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                            <tr>
                                <th scope="col" className='px-4 py-3'>Transaction ID</th>
                                <th scope="col" className='px-4 py-3'>Payment Date</th>
                                <th scope="col" className='px-4 py-3'>Capital (GHS)</th>
                                <th scope="col" className='px-4 py-3'>Interest Charges (GHS)</th>
                                <th scope="col" className='px-4 py-3'>Principal (GHS)</th>
                                <th scope="col" className='px-4 py-3'>Amount (GHS)</th>
                                <th scope="col" className='px-4 py-3'>Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRecords && (
                                currentRecords && currentRecords?.length > 0 ? (
                                    currentRecords?.map((item, index) => (
                                        <tr key={index} className="px-4 py-6 border-b hover:bg-gray-100 text-gray-6s00">
                                            <td className='px-4 py-3'>{item?.transactionId}</td>
                                            <td className='px-4 py-3'>{dateToHumanFormat(item?.date)}</td>
                                            <td className='px-4 py-3'>{item?.capital}</td>
                                            <td className='px-4 py-3'>{item?.interest}</td>
                                            <td className='px-4 py-3'>{item?.principal}</td>
                                            <td className='px-4 py-3'>{item?.payment && `${roundNum(item?.payment, 2)}`}</td>
                                            <td className='px-4 py-3 capitalize'>{item?.balance}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                        <td colSpan="7" className='px-4 py-3 text-center font-medium'>
                                            No payments made
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                        <tfoot>
                            <tr className="px-4 py-3 border-b">
                                <td className="px-4 py-3" colSpan={7}></td>
                            </tr>
                            <tr className="px-4 py-6 border-b hover:bg-gray-100 font-semibold">
                                <td className='px-4 py-3'>Total</td>
                                <td className='px-4 py-3'></td>
                                <td className='px-4 py-3'></td>
                                <td className='px-4 py-3'>{loan?.totalInterest}</td>
                                <td className='px-4 py-3'>{loan?.totalPrincipal}</td>
                                <td className='px-4 py-3'>{loan?.totalPaid && `₵ ${roundNum(loan?.totalPaid, 2)}`}</td>
                                <td className='px-4 py-3 capitalize'></td>
                            </tr>
                        </tfoot>
                    </table>
                    {currentRecords && 
                        <Pagination 
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            length={transactions?.length}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Transactions