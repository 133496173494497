import { useEffect, useState } from 'react';
import { fetchRequests } from '../../Requests/getRequests';
import { Topbar, Sidebar, Heading, Card } from "../../components"; // UsersChart, LoansChart
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from "../../slices/staffSlice";
import { authStaff } from "../../utils/constants";
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stats, setStats] = useState();

    useEffect(() => {
        let unsubscribed = false;
        const getCounts = async() => {
            let url = `/auth/staff/getStats`;
            let headers = authStaff(accessToken);

            let res = await fetchRequests(url, "GET", headers, {}, {});
            
            if(!unsubscribed){
                if(res?.code === 401){
                    // clear local storage and redirect to login
                    localStorage.clear();
                    navigate("/login");
                    dispatch(show({
                        type : "error",
                        state : true,
                        message : "Your session has expired. Please login to continue."
                    
                    }))
                    return;
                } else {
                    setStats(res);
                }
            }
        }

        getCounts();

        document.title = `Admin Home - ${process.env.REACT_APP_SITE_NAME}`;

    }, [accessToken, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className="section-container">
                    <Heading 
                        text="Dashboard"
                        size="text-3xl font-medium leading-6 text-display mb-5"
                        color="text-gray-700"
                    />

                    <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4'>
                        <div className="col-span-1">
                            <Card 
                                title="Staff"
                                count={stats?.staffCount > 0 ? stats?.staffCount : 0}
                            />
                        </div>

                        <div className="col-span-1">
                            <Card 
                                title="Clients"
                                count={stats?.clientCount > 0 ? stats?.clientCount : 0}
                            />
                        </div>

                        <div className="col-span-1">
                            <Card 
                                title="Active Loans"
                                count={stats?.activeCount > 0 ? stats?.activeCount : 0}
                            />
                        </div>

                        <div className="col-span-1">
                            <Card 
                                title="Completed Loans"
                                count={stats?.completedCount > 0 ? stats?.completedCount : 0}
                            />
                        </div>

                        <div className="col-span-1">
                            <Card 
                                title="Registered Businesses"
                                count={stats?.businessCount > 0 ? stats?.businessCount : 0}
                            />
                        </div>
                    </div>
                </section>

                {/* <section className='section-container'>
                    <Heading 
                        text="Reports"
                        size="text-3xl font-medium leading-6 text-display mb-3"
                        color="text-gray-700"
                    />

                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4'>
                        <div className='card'>
                            <UsersChart 
                                active={219}
                                inactive={434}
                            />
                        </div>

                        <div className='card'>
                            <LoansChart />
                        </div>
                    </div>
                </section> */}
            </main>
        </div>
    )
}

export default Index