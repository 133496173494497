import { useState } from 'react'
import { FormInput, FormSelect, FormRadio, Button } from "../../components";
import { businessTypeOpts, businessActivityOpts, businessSectorOpts, sortSubSectorOpts, employmentLevelOpts, employmentTypeOpts, employmentNatureOpts, demandGoodsOpts, customerTypeOpts, otherFinancialOpts, authStaff } from "../../utils/constants";
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function BusinessReg() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [name, setName] = useState('');
    const [owner, setOwner] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [location, setLocation] = useState('');
    const [phones, setPhones] = useState('');
    const [type, setType] = useState('');
    const [establishDate, setEstablishDate] = useState('');
    const [owners, setOwners] = useState('');
    const [busActivities, setBusActivities] = useState('');
    const [sector, setSector] = useState('');
    const [subsector, setSubsector] = useState('');
    const [incomeSource, setIncomeSource] = useState('');
    const [accountOperators, setAccountOperators] = useState('');
    const [assets, setAssets] = useState('');
    const [employLevel, setEmployLevel] = useState('');
    const [employType, setEmployType] = useState('');
    const [employNature, setEmployNature] = useState('');
    const [goodsDemand, setGoodsDemand] = useState('');
    const [customerTypes, setCustomerTypes] = useState([]);
    const [otherFinancial, setOtherFinancial] = useState([]);
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setProcessing(true);

        let data = {
            name,
            owner,
            email,
            address,
            location,
            phones,
            type,
            establishDate,
            owners,
            busActivities,
            sector,
            subsector,
            incomeSource,
            accountOperators,
            assets,
            employLevel,
            employType,
            employNature,
            goodsDemand,
            customerTypes,
            otherFinancial
        }

        let url = `/loans/staff/register-business`;
        let headers = authStaff(accessToken)
        
        let res = await fetchRequests(url, "post", headers, {}, data);
        setProcessing(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res.err
            }))
            return;
        }
        
        if(res?.message){
            dispatch(show({
                type : "success",
                state : true,
                message : `${res?.message}`
            }))
        }

        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    return (
        <form className='space-y-6' onSubmit={handleSubmit}>
            <h2 className='mt-10 mb-2 text-lg'>Details</h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
                <FormInput 
                    label="Name of Business"
                    type="text"
                    placeholder="Business Name"
                    id={"name"}
                    name={"name"}
                    value={name}
                    setValue={setName}
                />

                <FormInput 
                    label="Owner's Phone Number"
                    inputMode='numeric'
                    pattern='[0-9]*'
                    placeholder="Owner's Phone Number"
                    id={"owner"}
                    name={"owner"}
                    value={owner}
                    setValue={setOwner}
                />

                <FormInput 
                    label="Email address"
                    type="text"
                    placeholder="Business email address"
                    id={"email"}
                    name={"email"}
                    value={email}
                    setValue={setEmail}
                />

                <FormInput 
                    label="Address"
                    type="text"
                    placeholder="Business address"
                    id={"address"}
                    name={"address"}
                    value={address}
                    setValue={setAddress}
                />

                <FormInput 
                    label="Location (If different from address)"
                    type="text"
                    placeholder="Business address"
                    id={"location"}
                    name={"location"}
                    value={location}
                    setValue={setLocation}
                />

                <FormInput 
                    label="Phone Numbers"
                    type="text"
                    placeholder={"(numbers, separated by commas)"}
                    id={"phones"}
                    name={"phones"}
                    value={phones}
                    setValue={setPhones}
                />

                <FormSelect 
                    label='Type of Business'
                    name={"type"}
                    id={"type"}
                    content={businessTypeOpts}
                    value={type}
                    setValue={setType}
                />

                <div>
                    <label htmlFor="establishDate" className='form-label'>
                        Date of Business Establishment
                    </label>
                    <input 
                        type="date"
                        name={"establishDate"}
                        id={"establishDate"}
                        className="form-input disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-700 disabled:cursor-not-allowed"
                        value={establishDate}
                        onChange={e => setEstablishDate(e.target.value)}
                        min={new Date("1950-01-01").toISOString().split('T')[0]}
                        max={new Date().toISOString().split('T')[0]}
                    />
                </div>

                <FormInput 
                    label="Business Owners"
                    type="text"
                    placeholder={"(names, separated by commas)"}
                    id={"owners"}
                    name={"owners"}
                    value={owners}
                    setValue={setOwners}
                />

                <FormSelect 
                    label='What does your business do?'
                    name={"busActivities"}
                    id={"busActivities"}
                    content={businessActivityOpts}
                    value={busActivities}
                    setValue={setBusActivities}
                />

                <FormSelect 
                    label='Sector of Business'
                    name={"sector"}
                    id={"sector"}
                    content={businessSectorOpts}
                    value={sector}
                    setValue={setSector}
                />

                <FormSelect 
                    label='Subsector'
                    name={"subsector"}
                    id={"subsector"}
                    content={sortSubSectorOpts(sector)}
                    value={subsector}
                    setValue={setSubsector}
                />

                <FormInput 
                    label="Bank Account Details"
                    type="text"
                    id={"incomeSource"}
                    name={"incomeSource"}
                    value={incomeSource}
                    setValue={setIncomeSource}
                />

                <FormInput 
                    label="Those with authority to operate the account"
                    type="text"
                    placeholder={"(names, separated by commas)"}
                    id={"accountOperators"}
                    name={"accountOperators"}
                    value={accountOperators}
                    setValue={setAccountOperators}
                />

                <FormInput 
                    label="Total assets"
                    type="text"
                    id={"assets"}
                    name={"assets"}
                    value={assets}
                    setValue={setAssets}
                />

                <FormSelect 
                    label='Employment level'
                    name={"employLevel"}
                    id={"employLevel"}
                    content={employmentLevelOpts}
                    value={employLevel}
                    setValue={setEmployLevel}
                />

                <FormSelect 
                    label='Employment type'
                    name={"employType"}
                    id={"employType"}
                    content={employmentTypeOpts}
                    value={employType}
                    setValue={setEmployType}
                />

                <FormSelect 
                    label='Employment nature'
                    name={"employNature"}
                    id={"employNature"}
                    content={employmentNatureOpts}
                    value={employNature}
                    setValue={setEmployNature}
                />

                <FormSelect 
                    label='Demand for goods'
                    name={"goodsDemand"}
                    id={"goodsDemand"}
                    content={demandGoodsOpts}
                    value={goodsDemand}
                    setValue={setGoodsDemand}
                />

                <div className='grid gap-4 grid-cols-4 col-span-3'>
                    <div className='col-span-2'>
                        <FormRadio 
                            label='Customer Type'
                            type='checkbox'
                            content={customerTypeOpts}
                            value={customerTypes}
                            setValue={setCustomerTypes}
                        />
                    </div>

                    <div className='col-span-2'>
                        <FormRadio 
                            label='Other financial institutions'
                            type='checkbox'
                            content={otherFinancialOpts}
                            value={otherFinancial}
                            setValue={setOtherFinancial}
                        />
                    </div>
                </div>

                <div className='col-span-1'>
                    <Button 
                        btnText={"Register"} 
                        btnType={"submit"}
                        btnClasses={"form-button"} 
                        processing={processing}
                    />
                </div>
            </div>
        </form>
    )
}

export default BusinessReg