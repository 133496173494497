import React from 'react'
import { dateToHumanFormatShortWithTime } from "../../utils/constants";

function FlagBanner({ clientFlagged }) {
    return (
        <div className="flex space-x-3 p-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
            <svg className="shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Caution</span>
            <div className='inline-flex flex-col items-start space-y-3'>
                <span className="font-medium">Client account has been flagged.</span>
                <span>
                    <span className="font-medium"> Reason: </span> <span>{clientFlagged?.reason}</span>
                </span>
                {clientFlagged?.recommendation && (
                <span>
                    <span className="font-medium"> Recommendation: </span> <span>{clientFlagged?.recommendation}</span>
                </span>
                )}
                <span>
                    <span className="font-medium">Flagged by: </span> <span>{clientFlagged?.flaggedBy}</span>
                </span>
                <span>
                    <span className="font-medium">Flagged on: </span> <span>{dateToHumanFormatShortWithTime(clientFlagged?.createdAt)}</span>
                </span>
            </div>
        </div>
    )
}

export default FlagBanner