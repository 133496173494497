import { useEffect, useState } from 'react'
import Business from './Business';
import { authStaff } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import BusinessReg from './BusinessReg';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function BusInfo({ setBusinessSelect, phone }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [businesses, setBusinesses] = useState([]);
    const [isOpened, setIsOpened] = useState(false);
    const [selected, setSelected] = useState();

    const handleToggle = () => {
        setIsOpened(!isOpened);
    }

    const setBusiness = () => {
        setBusinessSelect(selected);
    }

    useEffect(() => {
        const fetchBusinesses = async() => {

            const url = `/loans/staff/get-businesses`;
            let headers = authStaff(accessToken);
            let params = {
                phone : phone
            }

            let res = await fetchRequests(url, "GET", headers, params, {});

            if(res?.code === 401){
                // clear local storage and redirect to login
                localStorage.clear();
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : res.err
                }))
                return;
            }

            setBusinesses(res);
        }

        fetchBusinesses();
    }, [accessToken, phone, dispatch, navigate, setBusinesses])

    return (
        <div className='px-4 space-y-6'>
            <h2 className='text-lg font-medium'>Select a business</h2>

            <div className='grid'>
                <Business businesses={businesses} setSelected={setSelected} />
            </div>

            <div className='flex gap-4 items-center'>
                <button className='border border-light-blue rounded-md px-4 py-2 text-light-blue hover:bg-light-blue hover:text-white transition delay-75 duration-100' onClick={handleToggle}>
                    Add Business
                </button>

                {/* <button className='px-3 py-2 text-white bg-gold-700 hover:bg-gold-500 text-sm rounded-md disabled:cursor-not-allowed disabled:bg-lighter-blue' disabled={!selected} onClick={setBusiness}>
                    Continue
                </button> */}

                <button className='inline-flex justify-center rounded-md text-sm font-semibold py-2.5 px-4 bg-gold-700 text-white hover:bg-gold-500 disabled:cursor-not-allowed disabled:bg-lighter-blue' disabled={!selected} onClick={setBusiness}>
                    Continue
                </button>
            </div>

            {isOpened && <BusinessReg />}
        </div>
    )
}

export default BusInfo