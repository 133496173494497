import { useState, useEffect } from 'react'
import { Topbar, Sidebar, Heading, Loader, Pagination } from "../../../components";
import { authStaff } from "../../../utils/constants";
import { adminPortalRequest } from "../../../Requests/getRequests";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken } from "../../../slices/staffSlice";
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';
import Item from './Item';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [businessList, setBusinessList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [metadata, setMetadata] = useState(null);
    const [currentPage, setCurrentPage] = useState(metadata?.currentPage || 1);
    const nPages = metadata?.totalPages;

    // create a function to handle pagination fetching
    const handlePagination = async(page) => {
        setIsLoading(true);

        let headers = authStaff(accessToken);
        let url = `/auth/staff/business/list`;
        let params = { page : page || 1 };

        let res = await adminPortalRequest(url, "GET", headers, params, {});
        setIsLoading(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
            navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        // set the loans and metadata to the paginated loans and metadata
        setBusinessList(res.businesses);
        setMetadata(res.metadata);
    }

    useEffect(() => {
        let unsubscribed = false;
        const getBusinesses = async(accessToken) => {
            setIsLoading(true);

            let businessUrl = `/auth/staff/business/list`;
            let headers = authStaff(accessToken);
            let params = { page : 1 }

            let res = await adminPortalRequest(businessUrl, "GET", headers, params, {});
            setIsLoading(false);
            
            if(!unsubscribed){
                if(res?.code === 401){
                    // clear local storage and redirect to login
                    localStorage.clear();
                    navigate("/login");
                    dispatch(show({
                        type : "error",
                        state : true,
                        message : "Your session has expired. Please login to continue."
                    }))
                    return;
                } 
                
                if(res?.err){
                    dispatch(show({
                        type : "error",
                        state : true,
                        message : `${res?.err}`
                    }))
                    return;
                }
                else {
                    setBusinessList(res.businesses);
                    setMetadata(res.metadata);
                }
            }
        }

        getBusinesses(accessToken);

        return () => {
            unsubscribed = true
        }
    },[accessToken, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className="section-container">
                    <div className='flex items-center justify-between mb-3'>
                        <Heading
                            text="Businesses"
                            size="text-3xl font-medium leading-6 text-display mb-5"
                            color="text-gray-700"
                        />

                        {isLoading && <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />}
                    </div>

                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                        <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b bg-gray-50'>
                                    <tr>
                                        <th scope="col" className='px-4 py-3 w-[20%]'>Name of Business</th>
                                        <th scope="col" className='px-4 py-3 w-[30%]'>Owners of Business</th>
                                        <th scope="col" className='px-4 py-3'>Phone Numbers</th>
                                        <th scope="col" className='px-4 py-3'>Date Established</th>
                                        <th scope="col" className='px-4 py-3'>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {businessList && businessList?.length > 0 ? (
                                        businessList?.map((item, index) => (
                                            <Item 
                                                key={index}
                                                item={item}
                                            />
                                        ))
                                    ) : (
                                        <tr className="px-4 py-6 border-b hover:bg-gray-100">
                                            <td colSpan="5" className='px-4 py-3 text-center font-medium'>
                                                No Businesses
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            {businessList && (
                                <Pagination 
                                    nPages={nPages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    length={metadata.totalCount}
                                    handlePagination={handlePagination}
                                />
                            )}
                        </div>
                    </div>

                </section>
            </main>
        </div>
    )
}

export default Index