import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../slices/staffSlice';
import { Topbar, Sidebar, Heading, FormInput, Button } from "../../../components";
import { authStaff } from '../../../utils/constants';
import { fetchRequests } from '../../../Requests/getRequests';
import { show } from '../../../slices/toastSlice';

function Create() {

    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const [first_name, setFirst_Name] = useState("");
    const [last_name, setLast_Name] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [process, setProcess] = useState(false);

    const createRequest = async(e) => {
        e.preventDefault();
        setProcess(true);

        let createUrl = `/auth/staff/addStaff`;
        let headers = authStaff(accessToken);
        let data = {
            surname : last_name,
            otherNames : first_name,
            phone : phone,
            email : email,
        }

        let res = await fetchRequests(createUrl, "POST", headers, {}, data);
        setProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : `Your session has expired. Please login to continue.`
            }))
            return;
        }
        
        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : `${res?.err}`
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `New staff added successfully`
        }))
        
        setTimeout(() => {
            navigate("/admin/staff")
        },1500)
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container'>
                    <div className='flex items-center justify-between mb-3'>
                        <Heading 
                            text={"Create Staff"}
                            size="text-3xl font-bold tracking-tight text-gray-700"
                        />

                    </div>

                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
                                <p className="mt-1 text-sm text-gray-600">
                                    This will add a neew staff member's details to the database to allow account creation.
                                </p>
                            </div>
                        </div>

                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <form id="create-staff">
                                <div className="shadow sm:overflow-hidden sm:rounded-md">
                                    <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-2 gap-6">
                                            <FormInput 
                                                label="First Name"
                                                name="first_name"
                                                id="first_name"
                                                type={"text"}
                                                required
                                                value={first_name}
                                                setValue={setFirst_Name}
                                            />

                                            <FormInput 
                                                label="Last Name"
                                                name="last_name"
                                                id="last_name"
                                                type={"text"}
                                                required
                                                value={last_name}
                                                setValue={setLast_Name}
                                            />

                                            <FormInput 
                                                label="Email Address"
                                                name="email"
                                                id="email"
                                                type={"email"}
                                                required
                                                value={email}
                                                setValue={setEmail}
                                            />

                                            <FormInput 
                                                label="Phone Number"
                                                name="phone"
                                                id="phone"
                                                type={"number"}
                                                required
                                                value={phone}
                                                setValue={setPhone}
                                            />

                                        </div>
                                    </div>

                                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6 space-x-4">
                                        <Link to="/admin/staff" className="cancel-button">
                                            Cancel
                                        </Link>

                                        <Button 
                                            btnType={"submit"}
                                            btnClasses={"default-button"}
                                            btnText="Add Staff"
                                            btnFunction={createRequest}
                                            processing={process}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </section>

            </main>
        
        </div>
    )
}

export default Create