import { useEffect, useState } from "react";
import { Topbar, Sidebar, Loader, BreadCrump, Flag, FlagBanner } from "../../components";
import { fetchRequests } from "../../Requests/getRequests";
import { useDispatch } from "react-redux";
import { show } from '../../slices/toastSlice';
import { authStaff } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectAccessToken } from "../../slices/staffSlice";
import { useLocation, useNavigate } from "react-router-dom";
import ClientTable from "./ClientTable";
import { FlagIcon } from '@heroicons/react/24/solid';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const clientId = location.pathname.split("/")[2];
    const [client, setClient] = useState();
    const [isLoading, setIsLoading] = useState(true);

    // flag modal functions
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isFlagModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    }
    const isFlagModalClosed = () => {
        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        const getUserDetails = async(clientId) => {
            let clientUrl = `/auth/staff/client/find`;
            let headers = authStaff(accessToken);
            let params = {
                _id : clientId
            }

            let res = await fetchRequests(clientUrl, "GET", headers, params, {});
            setIsLoading(false);

            if(res?.code === 401){
                // clear local storage and redirect to login
                localStorage.clear();
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }
            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : `${res?.err}`
                }))
                return;
            } 

            setClient(res);
        }

        getUserDetails(clientId);
    },[accessToken, clientId, dispatch, navigate])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content pb-36'>
                <section className='section-container space-y-6'> 

                    {client ? 
                        <>
                        <div className="flex items-center justify-between">
                            <BreadCrump 
                                path='/'
                                main="Dashboard"
                                sub={isLoading ? 
                                    <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : 
                                    `${client?.otherNames} ${client?.surname}'s Details - ${client?.isVerified ? 'Verified' : 'Not Verified'}`
                                }
                            />

                            <div className="grid grid-cols-1 min-h-[42px] w-auto items-center gap-2 overflow-hidden border border-gray-200 rounded-lg px-1.5 mb-2 text-sm">
                                {/* <Link to={`/client/edit/${clientId}`} className="inline-flex items-center px-2 py-1 text-gray-500 hover:bg-slate-100 rounded-md">
                                    <PencilIcon className='h-4 w-4 mr-2 text-gray-500' />
                                    Edit
                                </Link> */}

                                <button type='button' className='inline-flex items-center px-2 py-1 text-gray-500 hover:bg-slate-100 rounded-md' onClick={isFlagModalOpen}>
                                    <FlagIcon className='h-5 w-5 text-red-500 mr-2' />
                                    <span className="text-red-500">
                                        Flag
                                    </span>
                                </button>
                            </div>
                        </div>

                        {client?.clientFlagged && Object.keys(client.clientFlagged).length > 0 && (
                            <FlagBanner 
                                clientFlagged={client.clientFlagged}
                            />
                        )}

                        <div className='rounded-md'>
                            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                                <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                                    <table className='min-w-full'>
                                        <thead className='text-xs font-semibold tracking-wide text-left text-white uppercase border-b bg-gray-700'>
                                            <tr>
                                                <th scope="col" className='px-4 py-3 w-[40%]'>Detail</th>
                                                <th scope="col" className='px-4 py-3 w-[60%]'>Entry</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <ClientTable 
                                                client={client}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        </>
                    : 
                    <div className='w-full py-2 inline-flex justify-center items-center'>
                        <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                    </div>
                    }

                </section>
            </main>

            <Flag 
                isOpen={isModalOpen}
                isClosed={isFlagModalClosed}
                client={client}
            />
        </div>
    )
}

export default Index