import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../slices/staffSlice';
import { fetchRequests } from '../Requests/getRequests';
import { show } from '../slices/toastSlice';
import { authStaff } from '../utils/constants';
import { useNavigate } from 'react-router-dom';

function usePayments() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accessToken = useSelector(selectAccessToken);
    const [isLoading, setIsLoading] = useState(true);
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        let unsubscribed = false;

        const fetchPayments = async() => {
            setIsLoading(true);

            let headers = authStaff(accessToken);

            let url = `/payments/staff/get-payments`;

            let res = await fetchRequests(url, "GET", headers, {}, {});
            setIsLoading(false);

            if(res?.code === 401){
                // clear local storage and redirect to login
                localStorage.clear();
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }

            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : res?.err
                }))
                return;
            }

            if(!unsubscribed){
                setPayments(res);
            }
        }

        fetchPayments();

        return () => {
            unsubscribed = true;
        }
    }, [dispatch, navigate, accessToken, setIsLoading, setPayments])

    return { isLoading, payments }
}

export default usePayments