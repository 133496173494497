import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { Button, FormInput, FormSelect } from '../../components';
import { authStaff, education, employment, formatName, genderRoles, maritalStatusOpts, networkProviders, workStates } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import OTPInput from './OTPInput';
import { show } from '../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function NewClient({ isOpen, closeModal, phone, setClient, setIsToggled }) {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [otherNames, setOtherNames] = useState('');
    const [surname, setSurname] = useState('');
    const [network, setNetwork] = useState("");
    const [email, setEmail] = useState("");
    const [dob, setDob] = useState("");
    const [pob, setPob] = useState("");
    const [gender, setGender] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [edu, setEdu] = useState("");
    const [occupation, setOccupation] = useState("");
    const [nearestLandmark, setNearestLandmark] = useState("");
    const [digitalAddress, setDigitalAddress] = useState("");
    const [employNature, setEmployNature] = useState("");
    const [idNumber, setIDNumber] = useState("");
    const [address, setAddress] = useState("");
    const [process, setProcess] = useState(false);

    const handleSubmit = async() => {
        setProcess(true);

        let data = {
            code: code,
            phone: phone,
            network: network,
            otherNames: formatName(otherNames),
            surname: formatName(surname),
            email: email,
            dob: dob,
            pob: pob,
            gender : gender,
            maritalStatus: maritalStatus,
            education: edu,
            occupation: occupation,
            nearestLandmark: nearestLandmark,
            digitalAddress: digitalAddress,
            employNature: employNature,
            idNumber: idNumber,
            address: address
        }

        let url = `/loans/staff/onboarding`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "post", headers, {}, data);
        setProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res.err
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : `Onboarding successful...`
        }))

        // store client phone in localStorage
        localStorage.setItem("client", JSON.stringify({ 
            phone : res?.phone, 
            name : res?.otherNames + " " + res?.surname 
        }));
        setClient(res);
        setIsToggled(true);

        // navigate to next page
        setTimeout(() => {
            closeModal();
        }, 2000)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                    <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all md:max-w-[55vw] w-[80vw]">
                    <Dialog.Title as="h3" className="text-lg md:text-2xl font-medium leading-6 text-gray-900">
                        Onboard New Client
                    </Dialog.Title>
                    <div className="mt-4">
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                            <FormInput 
                                label="Phone Number"
                                type="text"
                                name={"phoneNum"}
                                id={"phoneNum"}
                                value={phone}
                                disabled={true}
                            />

                            <FormSelect
                                label="Mobile Network"
                                name={"network"}
                                id={"network"}
                                content={networkProviders}
                                value={network}
                                setValue={setNetwork}
                            />

                            <div>
                                <label htmlFor="otp-0" className="form-label">SMS Code</label>
                                <OTPInput len={4} setInput={setCode} />
                            </div>

                            <FormInput 
                                label="First Name"
                                type="text"
                                name={"first_name"}
                                id={"first_name"}
                                placeholder={"Enter first name"}
                                value={otherNames}
                                setValue={setOtherNames}
                            />
                            
                            <FormInput 
                                label="Surname"
                                type="text"
                                name={"last_name"}
                                id={"last_name"}
                                placeholder={"Enter surname"}
                                value={surname}
                                setValue={setSurname}
                            />

                            <FormInput 
                                label="Email Addess"
                                type="email"
                                name={"email"}
                                id={"email"}
                                placeholder={"Enter your email address"}
                                value={email}
                                setValue={setEmail}
                            />
                            
                            <FormInput 
                                label="Date of Birth"
                                type="date"
                                name={"dob"}
                                id={"dob"}
                                value={dob}
                                setValue={setDob}
                            />

                            <FormInput 
                                label="Place of birth"
                                type="pob"
                                name={"pob"}
                                id={"pob"}
                                value={pob}
                                setValue={setPob}
                            />

                            <FormSelect
                                label="Gender"
                                name={"gender"}
                                id={"gender"}
                                content={genderRoles}
                                value={gender}
                                setValue={setGender}
                            />

                            <FormSelect
                                label="Marital Status"
                                name={"marital_status"}
                                id={"marital_status"}
                                content={maritalStatusOpts}
                                value={maritalStatus}
                                setValue={setMaritalStatus}
                            />

                            <FormSelect
                                label="Education"
                                name={"education"}
                                id={"education"}
                                content={education}
                                value={edu}
                                setValue={setEdu}
                            />

                            <FormSelect
                                label="Occupation"
                                name={"occupation"}
                                id={"occupation"}
                                content={workStates}
                                value={occupation}
                                setValue={setOccupation}
                            />

                            <FormInput 
                                label="Nearest Landmark"
                                type="text"
                                name={"nearestLandmark"}
                                id={"nearestLandmark"}
                                value={nearestLandmark}
                                setValue={setNearestLandmark}
                            />

                            <FormInput 
                                label="Digital Address"
                                type="text"
                                name={"digitalAddress"}
                                id={"digitalAddress"}
                                value={digitalAddress}
                                setValue={setDigitalAddress}
                            />

                            <FormSelect
                                label="Nature of Employment"
                                name={"employNature"}
                                id={"employNature"}
                                content={employment}
                                value={employNature}
                                setValue={setEmployNature}
                            />

                            <FormInput 
                                label="GhanaCard Number"
                                type="text"
                                name={"idNumber"}
                                id={"idNumber"}
                                placeholder={"GHA-123456789-0"}
                                value={idNumber}
                                setValue={setIDNumber}
                            />

                            <div className="col-span-full">
                                <label htmlFor="address" className="form-label">Address</label>
                                <textarea name='address' id='address' placeholder='Enter address' rows={4} value={address} onChange={(e) => setAddress(e.target.value)} className='form-textarea disabled:opacity-75 disabled:bg-gray-200 placeholder:text-gray-400 disabled:cursor-not-allowed'></textarea>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <Button 
                            btnClasses={`inline-flex justify-center rounded-md border border-light-blue px-4 py-2 text-sm font-medium text-light-blue hover:bg-light-blue hover:text-white transition delay-75 duration-200 ${process ? 'bg-light-blue' : 'bg-transparent'}`}
                            btnText={"Submit"}
                            btnType={"button"}
                            btnFunction={handleSubmit}
                            processing={process}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    )
}

export default NewClient