import { Fragment, useState, forwardRef } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, Cog6ToothIcon } from '@heroicons/react/20/solid'
import { Toggle, Button } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { useNavigate } from 'react-router-dom';
import { authStaff } from '../../utils/constants';
import { fetchRequests } from '../../Requests/getRequests';
import { show } from '../../slices/toastSlice';

function LoanSettings({ loan }) {

    const acceessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [reschedule, setReschedule] = useState(loan?.reschedule === "active" ? false : true);
    const [allMessages, setAllMessages] = useState(loan?.messaging?.other);
    const [balanceMessages, setBalanceMessages] = useState(loan?.messaging?.balance);
    const [penalMessages, setPenalMessages] = useState(loan?.messaging?.penalRate);
    const [processing, setProcessing] = useState(false);

    const handleRequest = async() => {
        setProcessing(true);

        let url = `/loans/staff/loan-settings`;
        let headers = authStaff(acceessToken);
        let data = {
            _id : loan?._id,
            reschedule,
            messaging : {
                other : allMessages,
                balance : balanceMessages,
                penalRate : penalMessages
            }
        }

        let res = await fetchRequests(url, "POST", headers, {}, data);
        setProcessing(false);

        if(res.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res?.err
            }))
            return;
        }

        dispatch(show({
            type : "success",
            state : true,
            message : "Loan settings updated successfully"
        }))

        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    const ToggleSwitch = forwardRef((props, ref) => {
        let { label, value, setValue } = props;
        return (
            <Toggle 
                label={label}
                value={value} 
                setValue={setValue}
            />
        )
    });

    const ButtonComponent = forwardRef((props, ref) => {
        let { btnText, btnClasses, btnFunction, processing, btnType } = props;
        return (
            <Button 
                btnText={btnText}
                btnClasses={btnClasses}
                btnFunction={btnFunction}
                processing={processing}
                btnType={btnType}
            />
        )
    });

    return (
        <Menu as="div" className="relative inline-block text-left -mt-3">
            <div>
                <Menu.Button className="inline-flex min-h-[42px] w-max items-center gap-0.5 overflow-hidden border border-gray-200 rounded-lg px-3 py-1 mb-2 text-sm hover:shadow-sm">
                    <Cog6ToothIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Loan Settings
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg px-3 py-2 z-50">
                    <div className="px-1 py-1">
                        <Menu.Item>
                            <ToggleSwitch 
                                label="Reschedule loan" 
                                value={reschedule} 
                                setValue={setReschedule}     
                            />
                        </Menu.Item>
                        <div className="divider my-2 bg-slate-100"></div>
                        <Menu.Item>
                            <ToggleSwitch 
                                label={`All Messages ${loan?.messaging?.other ? 'enabled' : 'disabled'}`} 
                                value={allMessages} 
                                setValue={setAllMessages}      
                            />
                        </Menu.Item>
                        <div className="divider my-2 bg-slate-100"></div>
                        <Menu.Item>
                            <ToggleSwitch 
                                label={`Balance Messages ${loan?.messaging?.balance ? 'enabled' : 'disabled'}`} 
                                value={balanceMessages} 
                                setValue={setBalanceMessages}      
                            />
                        </Menu.Item>
                        <div className="divider my-2 bg-slate-100"></div>
                        <Menu.Item>
                            <ToggleSwitch 
                                label={`Penal Messages ${loan?.messaging?.penalRate ? 'enabled' : 'disabled'}`} 
                                value={penalMessages} 
                                setValue={setPenalMessages}
                            />
                        </Menu.Item>
                        <div className="divider my-2 bg-slate-100"></div>
                        <Menu.Item>
                            <ButtonComponent 
                                btnText={"Save"}
                                btnClasses={"form-button w-full px-6 mt-2"}
                                btnFunction={handleRequest}
                                processing={processing}
                                btnType={'button'}
                            />
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default LoanSettings