import { useEffect } from 'react';
import { Topbar, Sidebar, Summary, SearchLoan, SearchClient, Reports } from "../../components";

function Index() {

    useEffect(() => {
        document.title = `Home - ${process.env.REACT_APP_SITE_NAME}`;
    },[])

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content pb-36'>
                <Summary />

                {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-4'> */}
                    <SearchLoan />
                {/* </div> */}

                <SearchClient />
                
                <Reports />
            </main>
        </div>
    )
}

export default Index