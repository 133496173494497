import { useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Topbar, Sidebar, AccountIndex, ApplicationIndex, CreditIndex, FilesIndex, StatusIndex, Loader, BreadCrump, Flag } from "../../components";
import { useLocation } from "react-router-dom";
import { useLoan } from '../../hooks';
import { FlagIcon } from '@heroicons/react/24/solid';

function ApprovedLoan() {

    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const loanType = path[2];
    const loanId = path[3];

    const { isLoading, loan } = useLoan(
        { loanId, loanType, state : null, approved : true, active : false, completed : false }
    );

    // flag modal functions
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isFlagModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    }
    const isFlagModalClosed = () => {
        setIsModalOpen(!isModalOpen);
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-6'>

                {loan ? 
                    <>
                    <div className="flex items-center justify-between">
                        <BreadCrump 
                            path='/facility-letters'
                            main="Approved Loans"
                            sub={isLoading ? <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : `${loan?.type} - ${loan?._id}`}
                        />

                        <div className="inline-flex min-h-[42px] w-max items-center gap-0.5 overflow-hidden border border-gray-200 rounded-lg px-1.5 mb-2 text-sm divide-x">
                            <button type='button' className='inline-flex items-center px-1 py-1.5 text-gray-500 hover:bg-slate-100 rounded-md' onClick={isFlagModalOpen}>
                                <FlagIcon className='h-5 w-5 text-red-500 ml-2' />
                                <span className="px-2 text-red-500">
                                    Flag
                                </span>
                            </button>
                        </div>
                    </div>

                    <div>
                        <TabGroup>
                            <TabList className="flex space-x-1 rounded-xl p-1">
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Status
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Application
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Credit Bureau
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Files
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Loan Account
                                </Tab>
                            </TabList>

                            <TabPanels className="mt-2">
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <StatusIndex loan={loan} />
                                </TabPanel>
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <ApplicationIndex loan={loan} />
                                </TabPanel>
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <CreditIndex loan={loan} />
                                </TabPanel>
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <FilesIndex loan={loan} />
                                </TabPanel>
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <AccountIndex loan={loan} />
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                    </div>
                </>
                : 
                <div className='w-full py-2 inline-flex justify-center items-center'>
                    <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                </div>
                }
                </section>

                <Flag 
                    isOpen={isModalOpen}
                    isClosed={isFlagModalClosed}
                />
            </main>
        </div>
    )
}

export default ApprovedLoan