import { useState } from 'react';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { Topbar, Sidebar, AccountIndex, ApplicationIndex, CreditIndex, FilesIndex, Loader, BreadCrump, LoanSettings, LoanState, Flag, FlagBanner } from "../../components";
import { useLocation } from "react-router-dom";
import { loanStates } from "../../utils/constants";
import { useLoan } from '../../hooks';
import { FlagIcon } from '@heroicons/react/24/solid';

function Loan() {

    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split("/");
    const loanState = path[1];
    const loanId = path[2];

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    // flag modal functions
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isFlagModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    }
    const isFlagModalClosed = () => {
        setIsModalOpen(!isModalOpen);
    }

    const { isLoading, loan } = useLoan(
        { loanId, loanType : null, state : loanState, approved : false, active : loanState === loanStates.active, completed : loanState === loanStates.completed, rejected : loanState === loanStates.rejected}
    )

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container space-y-6'>

                {loan ?
                    <>
                    <div className='flex items-center justify-between'>
                        <BreadCrump 
                            path={loanState === loanStates.active ? "/active-loans" : loanState === loanStates.completed ? "/completed-loans" : "/rejected-loans"}
                            main={loanState === loanStates.active ? "Active Loans" : loanState === loanStates.completed ? "Completed Loans" : "Rejected Loans"}
                            sub={isLoading ? <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} /> : `${loan?.type} - ${loan?._id}`}
                        />

                        {!isLoading ? 
                            <div className='space-x-1.5'>
                                <button type='button' className='inline-flex items-center px-1 py-1.5 text-gray-500 hover:bg-slate-100 rounded-md' onClick={isFlagModalOpen}>
                                    <FlagIcon className='h-5 w-5 text-red-500 ml-2' />
                                    <span className="px-2 text-red-500">
                                        Flag
                                    </span>
                                </button>
                                
                                <LoanSettings 
                                    loan={loan}     
                                /> 
                            </div>
                            : null}
                    </div>

                    {loan?.clientFlagged && Object.keys(loan.clientFlagged).length > 0 && (
                        <FlagBanner 
                            clientFlagged={loan.clientFlagged}
                        />
                    )}

                    {loanState === loanStates.active && 
                        <LoanState loan={loan} />
                    }

                    <div>
                        <TabGroup>
                            <TabList className="flex space-x-1 rounded-xl p-1">
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Loan Account
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Application
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Credit Bureau
                                </Tab>
                                <Tab className={({ selected }) => classNames('w-full rounded-lg py-2.5 px-2 text-sm font-medium leading-5 text-light-blue', selected ? 'bg-light-blue shadow text-white outline-none focus-none' : 'text-dark-blue hover:bg-light-blue hover:text-white')}>
                                    Files
                                </Tab>
                            </TabList>

                            <TabPanels className="mt-2">
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <AccountIndex loan={loan} />
                                </TabPanel>
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <ApplicationIndex loan={loan} />
                                </TabPanel>
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <CreditIndex loan={loan} />
                                </TabPanel>
                                <TabPanel className={classNames('rounded-xl bg-white p-1')}>
                                    <FilesIndex loan={loan} />
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>
                    </div>
                </>
                : 
                <div className='w-full py-2 inline-flex justify-center items-center'>
                    <Loader color={"text-gray-700"} size={"w-5 h-5 ml-2"} />
                </div>
                }
                </section> 
            </main>

            <Flag 
                isOpen={isModalOpen}
                isClosed={isFlagModalClosed}
                client={loan?.client}
            />
        </div>
    )
}

export default Loan