import { useState } from 'react';
import { Topbar, Sidebar, FormInput, Button } from "../../components";
import { authStaff, loanFilters } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../slices/staffSlice';
import { fetchRequests } from '../../Requests/getRequests';
import NewClient from './NewClient';
import RadioBtns from './RadioBtns';
import { show } from '../../slices/toastSlice';

function Index() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loanType, setLoanType] = useState();
    const [phoneNum, setPhoneNum] = useState('');
    const [client, setClient] = useState();
    const [isToggled, setIsToggled] = useState(false);
    const [process, setProcess] = useState(false);

    // for the new client modal
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => setIsOpen(false);

    const handleSubmit = async() => {
        setProcess(true);

        let params = {
            phoneNum : phoneNum
        }

        let url = `/loans/staff/preloan`;
        let headers = authStaff(accessToken);

        let res = await fetchRequests(url, "get", headers, params, {});
        setProcess(false);

        if(res?.code === 401){
            // clear local storage and redirect to login
            localStorage.clear();
			navigate("/login");
            dispatch(show({
                type : "error",
                state : true,
                message : "Your session has expired. Please login to continue."
            }))
            return;
        }

        if(res.err === "Client does not exist"){
            setIsOpen(true);
            return;
        }

        if(res?.err){
            dispatch(show({
                type : "error",
                state : true,
                message : res.err
            }))
            return;
        }
        
        // store client phone in localStorage
        localStorage.setItem("client", JSON.stringify({ 
            phone : res?.phone, 
            name : res?.otherNames + " " + res?.surname 
        }));
        setClient(res);
        setIsToggled(true);
    }

    // after selecting client for loan application
    const handleNav = () => {
        if(!loanType){
            dispatch(show({
                type : "error",
                state : true,
                message : "Please select a loan type"
            }))
            return;
        }

        navigate(`/apply/${loanType}`);
    }

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className='section-container'>

                    <h3 className='text-3xl font-medium leading-6 text-display mb-10'>
                        <span className='capitalize'>Select Loan Type</span>
                    </h3>

                    <RadioBtns
                        options={loanFilters}
                        loanType={loanType}
                        setLoanType={setLoanType}
                    />

                    <div className='mt-10'>
                        <div className='md:w-96'>
                            <FormInput 
                                label="Enter phone number"
                                type="text"
                                name={"phone_number"}
                                id={"phone_number"}
                                placeholder={"Enter phone number"}
                                value={phoneNum}
                                setValue={setPhoneNum}
                            />
                        </div>

                        <Button 
                            btnText={"Submit"}
                            btnType={"button"}
                            btnFunction={handleSubmit}
                            btnClasses={"form-button my-6 w-24"}
                            processing={process}
                            disabled={phoneNum.length !== 10}
                        />
                    </div>

                    {isToggled && (
                        <>
                        <h3 className='mb-3 font-medium text-xl leading-6 text-display'>
                            Existing Clients
                        </h3>
                        {client && Object.keys(client).length > 0 && (
                            <div className='px-4 py-4 rounded-md border border-slate-200 shadow-sm flex items-center justify-between'>
                                <span className='font-medium'>
                                    <span className='text-gold-500'>{client?.otherNames + " " + client?.surname}</span> <br />
                                    <span>{client?.phone}</span>
                                </span>

                                <Button 
                                    btnText={"Proceed"}
                                    btnType={"button"}
                                    btnFunction={handleNav}
                                    btnClasses={"text-sm bg-light-blue px-3 py-2 rounded-md text-white"}
                                />
                            </div>
                        )}
                        </>
                    )}
                    
                </section>
            </main>

            <NewClient 
                isOpen={isOpen}
                closeModal={closeModal}
                phone={phoneNum}
                setClient={setClient}
                setIsToggled={setIsToggled}
            />
        </div>
    )
}

export default Index