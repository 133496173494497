import { useState, useEffect } from 'react';
import { Topbar, Sidebar, ClientData, Loader, Suspend } from "../../../components";
import { authStaff } from "../../../utils/constants";
import { adminPortalRequest } from "../../../Requests/getRequests";
import { useDispatch, useSelector } from "react-redux";
import { selectAccessToken, selectStaffId } from "../../../slices/staffSlice";
import { show } from '../../../slices/toastSlice';
import { useNavigate } from 'react-router-dom';

function Edit() {

    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clientId = useSelector(selectStaffId);
    const [client, setClient] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getUserDetails = async(accessToken, clientId) => {
            let clientUrl = `/auth/staff/client/find`;
            let headers = authStaff(accessToken)
            let params = {
                _id : clientId
            }

            let res = await adminPortalRequest(clientUrl, "GET", headers, params, {});

            if(res?.code === 401){
                // clear local storage and redirect to login
                localStorage.clear();
                navigate("/login");
                dispatch(show({
                    type : "error",
                    state : true,
                    message : "Your session has expired. Please login to continue."
                }))
                return;
            }
            if(res?.err){
                dispatch(show({
                    type : "error",
                    state : true,
                    message : `${res?.err}`
                }))
                return;
            } else {
                setLoading(false);
                setClient(res);
            }
        }

        getUserDetails(accessToken, clientId);
    },[accessToken, clientId, dispatch, navigate])

    

    return (
        <div className='main-container'>
            <Topbar />
            <Sidebar />

            <main className='main-content'>
                <section className="section-container space-y-5">
                    {loading ? 
                        <Loader 
                            color="text-gray-700"
                            size="5"
                        />
                    : 
                    <>
                    <div className='flex items-center justify-end mb-3'>

                        <div className="inline-flex md:space-x-4 items-center">
                            <span className='hidden md:block'>Actions:</span>
                            <div className='button-groups'>
                                {/* <Verify 
                                    status={client?.isVerified}
                                /> */}
                                <Suspend 
                                    _id={client?._id}
                                    status={client?.isDisabled}
                                />
                            </div>
                        </div>
                    </div>

                    <ClientData 
                        client={client}
                    />

                    </>
                    }

                </section>
            </main>

        </div>
    )
}

export default Edit